import * as React from "react"
import { Link } from "gatsby"
import classNames from "classnames"

import Page from '../components/Page'
import Container from '../components/Container'


// markup
const TermsConditions = () => {
  return (
    <Page  className={classNames("policy-page")}>      
      <Container>
        <h1><strong>Terms &amp; Conditions</strong></h1>
         <section>
          <p>
            By visiting Loopr website, these terms will automatically apply to
            you – you should make sure therefore that you read them carefully
            before using the website. You’re not allowed to copy, or modify the
            app or website, any content in the app or website, or our trademarks
            in any way. You’re not allowed to attempt to extract the source code
            of the website, and you also shouldn’t try to translate the
            app/website and its content into other languages, or make derivative
            versions thereof. The app and website itself, and all the trade
            marks, copyright, database rights and other intellectual property
            rights related to it, still belong to Loopr.
          </p>
          <p>
            Loopr is committed to ensuring that website is as useful and
            efficient as possible. For that reason, we reserve the right to make
            changes to the website or to charge for its services, at any time
            and for any reason. We will never charge you for the website or its
            services without making it very clear to you exactly what you’re
            paying for.
          </p>
          <p>
            The website stores and processes personal data that you have
            provided to us, in order to provide our Service. It’s your
            responsibility to keep your phone and access to the website secure.
            We therefore recommend that you do not jailbreak or root your
            device, which is the process of removing software restrictions and
            limitations imposed by the official operating system of your device.
            It could make your phone vulnerable to malware/viruses/malicious
            programs, compromise your phone’s security features and it could
            mean that the website won’t work properly or at all.
          </p>
          <p>
            You should be aware that there are certain things that Loopr will
            not take responsibility for. Certain functions of the website will
            require the website to have an active internet connection. The
            connection can be Wi-Fi, or provided by your mobile network
            provider, but Loopr cannot take responsibility for the website not
            working at full functionality if you don’t have access to mobile
            internet or Wi-Fi, and you don’t have any of your data allowance
            left.
          </p>
          <p>
            If you’re using the website outside of an area with Wi-Fi, you
            should remember that your terms of the agreement with your mobile
            network provider will still apply. As a result, you may be charged
            by your mobile provider for the cost of data for the duration of the
            connection while accessing the website, or other third party
            charges. In using the website, you’re accepting responsibility for
            any such charges, including roaming data charges if you use the
            website outside of your home territory (i.e. region or country)
            without turning off data roaming. If you are not the bill payer for
            the device on which you’re using the app, please be aware that we
            assume that you have received permission from the bill payer for
            using the website.
          </p>
          <p>
            Along the same lines, Loopr cannot always take responsibility for
            the way you use the app i.e. You need to make sure that your device
            stays charged – if it runs out of battery and you can’t turn it on
            to avail the Service, Loopr cannot accept responsibility
          </p>
          <p>
            With respect to Loopr’s responsibility for your use of the website,
            when you’re using the website, it’s important to bear in mind that
            although we endeavour to ensure that it is updated and correct at
            all times, we do rely on third parties to provide information to us
            so that we can make it available to you. Loopr accepts no liability
            for any loss, direct or indirect, you experience as a result of
            relying wholly on this functionality of the Loopr.
          </p>
          <p>
            At some point, we may wish to update the website. The website is
            currently available on Android , iOS and web – the requirements for
            both systems (and for any additional systems we decide to extend the
            availability of the app to) may change, and you’ll need to download
            the updates if you want to keep using the website. Loopr does not
            promise that it will always update the app/website so that it is
            relevant to you and/or works with the iOS/Android version that you
            have installed on your device. However, you promise to always accept
            updates to the website when offered to you, We may also wish to stop
            providing the website, and may terminate use of it at any time
            without giving notice of termination to you. Unless we tell you
            otherwise, upon any termination, (a) the rights and licenses granted
            to you in these terms will end; (b) you must stop using the app, and
            (if needed) delete it from your device.
          </p>
        </section>
        <section>
          <h4>
            <strong className="green">
              Changes to This Terms and Conditions
            </strong>
          </h4>
          <p>
            We may update our Terms and Conditions from time to time. Thus, you
            are advised to review this page periodically for any changes. We
            will notify you of any changes by posting the new Terms and
            Conditions on this page. These changes are effective immediately
            after they are posted on this page.
          </p>
        </section>
        <section>
          <h4>
            <strong className="green">Contact Us</strong>
          </h4>
          <p>
            If you have any questions or suggestions about our Terms and
            Conditions, do not hesitate to{" "}
            <Link to="/contact-us"><b>Contact Us</b></Link>.
          </p>
        </section>
      </Container>          
    </Page>
  )
}

export default TermsConditions
